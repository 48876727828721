import React, { Component } from 'react';

class Contact extends Component {
    state = {  }
    render() { 
        return (
            <div className="contact">
                <a href="sms:+19083082336">
                    <button className="text">Reach Josh by SMS</button>
                </a>
                <a href="https://t.me/joshdate">
                    <button className="telegram">Reach Josh by Telegram</button>
                </a>
                <a href="https://tinder.com/@notjoshinyou">
                    <button className="tinder">Reach Josh by Tinder</button>
                </a>
            </div>
        );
    }
}
 
export default Contact;