import React, { Component } from 'react';
import 'animate.css';

class Nav extends Component {
    state = { 
        isAnimating: true
     }
    render() { 
        return (
            <nav>
                <div className="container">
                    {this.state.isAnimating && <img className="logo animate__animated animate__hinge animate__delay-2s" src="https://upload.wikimedia.org/wikipedia/commons/c/ce/Hinge_Logo.png" alt="Hinge logo" />}
                    {!this.state.isAnimating && <h1 className="animate__animated animate__fadeInDown">Josh</h1>}
                </div>
            </nav>
        );
    }


    componentDidMount() {
        setTimeout(() => {
            this.setState({ isAnimating: false });
        },4000);
    }
}
 
export default Nav;