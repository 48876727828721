import React, { Component } from 'react';
import './App.css';
import Nav from './components/nav';
import Header from './components/header';

import Chatbot from "react-chatbot-kit";
import ActionProvider from './resources/chatbot/ActionProvider';
import MessageParser from './resources/chatbot/MessageParser';
import config from './resources/chatbot/config';
import QuickFacts from './components/sections/quickfacts';
import ClaimToFame from './components/sections/claimToFame';

class App extends Component {
  state = {
    chatShown: false
  }
  render() { 
    return (
      <>
        <Nav />
        <Header />
        <Chatbot className="test" config={config} messageParser={MessageParser} actionProvider={ActionProvider}/>
        <div onClick={this.hideShowChat} className="chatBoxExpandButton bg-grey">
          <i className="material-icons">chat</i>
        </div>
        <QuickFacts />
        <ClaimToFame />
      </>
    );
  }

  hideShowChat = () => {
    this.setState({chatShown: !this.state.chatShown}, () => {
      console.log(this.state.chatShown);
      if(this.state.chatShown) {
        document.querySelector('.react-chatbot-kit-chat-container').classList.add('shown');
      } else {
        document.querySelector('.react-chatbot-kit-chat-container').classList.remove('shown');
      }
    })
  }

  componentDidMount = () => {
    setTimeout(() => {this.setState({
      chatShown: true
    }, () => {
      document.querySelector('.react-chatbot-kit-chat-container').classList.add('shown');
    })}, 3000);
  }
}
 
export default App;