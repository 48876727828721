import React, { Component } from 'react';
import biden from '../../resources/img/biden.jpg';
import jams from '../../resources/img/jams.mp4';

class ClaimToFame extends Component {
    render() {
        return (
            <section className="p-5">
                <div className="container-fluid">
                    <div className="row">
                        <h1 className="text-center">
                            <span className="bg-white px-md-3 px-1">My Highlights Reel</span>
                        </h1>
                    </div>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div className="my-3 text-center card">
                            <div className="card-body">
                            <h2 className="fs-3 sans-serif">Trolled Joe Biden</h2>
                            <img className="img-fluid" src={biden} alt="Huffington Post: Debate's Biggest Winner May Have Been a Biden-Trolling Prankster" />
                            <p></p>
                            </div>
                        </div>
                        <div className="my-3 text-center card">
                            <div className="card-body">
                            <h2 className="fs-3 sans-serif">Jammin</h2>
                            <video className="mw-100" src={jams} controls></video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default ClaimToFame;