import { createChatBotMessage } from "react-chatbot-kit";
import botAvatar from './../img/me.jpg'
import Options from './widgets/options';
import Harper from './widgets/harper';
import Deets from './widgets/deets';
import BotAvatar from './widgets/botAvatar';
import Contact from './widgets/contact';

const config = {
    botName: "MiniJosh",
    customStyles: {
        botMessageBox: {
            backgroundColor: "#6e6b6b"
        },
        chatButton: {
            backgroundColor: "#6e6b6b",
            cursor: "pointer"
        }
    },
    customComponents: {
        botAvatar: () => <BotAvatar />
    },
    initialMessages: [
        createChatBotMessage(`Howdy! I'm MiniJosh, Josh's digital wingman!`, {delay: 4000}),
        createChatBotMessage(`I can answer some basic questions about Josh, and if we're ready to take things to the next level, put you in touch with him!`,
            {
                delay: 6000,
                widget: 'options'
            })
    ],

    widgets: [
        {
            widgetName: "options",
            widgetFunc: (props) => <Options {...props} />,
        },
        {
            widgetName: "harper",
            widgetFunc: (props) => <Harper {...props} />
        },
        {
            widgetName: "deets",
            widgetFunc: (props) => <Deets {...props} />
        },
        {
            widgetName: "contact",
            widgetFunc: (props) => <Contact {...props} />
        }
      ]
}

  
export default config;