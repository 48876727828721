import React, { Component } from 'react';
import joshBot from '../../img/helloBitmoji.png';

class BotAvatar extends Component {
    state = {  }
    render() { 
        return <img className="botAvatar" src={joshBot} alt="MiniJosh Bot Avatar" />;
    }
}
 
export default BotAvatar;