import React, { Component } from 'react';

class QuickFacts extends Component {
    facts = [
        {
            title: 'Age',
            value: (Math.floor((Date.now()-new Date('11-25-1997').getTime())/31556952000)).toString()
        },
        {
            title: 'Pronouns',
            value: 'He/Him'
        },
        {
            title: 'Pets',
            value: 'Rabbit! Ask MiniJosh for pics :)'
        },
        {
            title: 'Location',
            value: 'New Jersey'
        },
        {
            title: 'Education',
            value: 'Syraucse University Class of 2020'
        },
        {
            title: 'Hobbies',
            value: 'Cooking 👩‍🍳, chess ♟, and building pointless websites'
        },
        {
            title: 'Favorite food',
            value: 'Tacos 🌮'
        },
        {
            title: 'Favorite drinks',
            value: 'Tea, coffee and hot apple cider ☕'
        },
        {
            title: 'Favorite color',
            value: 'Purple 🟣'
        },
        {
            title: 'Favorite animal',
            value: 'Bunny 🐇'
        },
        {
            title: 'Favorite TV show',
            value: 'Star Trek: The Next Generation'
        },
        {
            title: 'Favorite band',
            value: <span>Melt. Give em a listen below!</span>,
            extra: <iframe style={{maxWidth: "100%"}} src="https://open.spotify.com/embed/artist/0G7KI9I5BApiXc5Sqpyil9" width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        }
    ]
    render() {
        return (
            <section className="bg-dark text-light p-3 py-5">
                <div className="container">
                    <div className="row">
                    <h1 className="text-center">Quick facts</h1>
                    </div>
                    <div className="row align-items-stretch flex-nowrap quick-facts cursor-pointer">
                        {this.facts.map((fact) => {
                            return (
                                <div className="my-3">
                                    <div className="card text-dark h-100 flex-column d-flex justify-content-center">
                                        <div className="headerText text-center">
                                            <h1>{fact.title}</h1>
                                            <p className="my-2">{fact.value}</p>
                                            {fact.extra ? fact.extra : null}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        );
    }

    componentDidMount = () => {
        const slider = document.querySelector(".quick-facts");
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", e => {
        if (window.innerWidth < 768) return;
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
        if ( window.innerWidth < 768) return;
        isDown = false;
        slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
        if (window.innerWidth < 768);
        isDown = false;
        slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", e => {
        if (!isDown || window.innerWidth < 768) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = x - startX;
        slider.scrollLeft = scrollLeft - walk;
        });
    }
}
 
export default QuickFacts;