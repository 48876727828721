import React, { Component } from 'react';
import harper from '../../img/harper.jpg';

class Harper extends Component {
    state = {  }
    render() { 
        return (<>
            <img src={harper} alt="A pic of my lovely bun!"/>
        </>);
    }
}
 
export default Harper;