import React, { Component } from 'react';

class Options extends Component {
    state = {  }
    render() { 
        return (
            <div>
                
                <button className="optionButton" onClick={this.props.actionProvider.handleJob}>Josh's job</button>
                <button className="optionButton" onClick={this.props.actionProvider.handlePets}>Josh's pets</button>
                <button className="optionButton" onClick={this.props.actionProvider.handleWhereFrom}>Where's Josh from?</button>
                <button className="optionButton" onClick={this.props.actionProvider.handleHobbies}>Hobbies</button>
                <button className="optionButton" onClick={this.props.actionProvider.handleColors}>Josh's favorite colors</button>
                <button className="optionButton" onClick={this.props.actionProvider.handleDeets}>How can I reach Josh?</button>
            </div>
        );
    }
}
 
export default Options;