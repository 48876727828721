class MessageParser {
    constructor(actionProvider, state) {
      this.actionProvider = actionProvider;
      this.state = state;
    }
    
    parse = (message) => {
        const lowerCase = message.toLowerCase();

        if(
            lowerCase.includes('colors') ||
            lowerCase.includes('color')
        ) {
            return this.actionProvider.handleColors();
        } else if (
            lowerCase.includes('pets') ||
            lowerCase.includes('pet') ||
            lowerCase.includes('animals') ||
            lowerCase.includes('animal') ||
            lowerCase.includes('harper') ||
            lowerCase.includes('rabbit') ||
            lowerCase.includes('rabbits') ||
            lowerCase.includes('bunny') ||
            lowerCase.includes('bunnies')
        ) {
            return this.actionProvider.handlePets();        
        } else if (
            lowerCase.includes('penis') ||
            lowerCase.includes('dick') || 
            lowerCase.includes('cock') || 
            lowerCase.includes('fuck') ||
            lowerCase.includes('69')
        ) {
            return this.actionProvider.handleExplicit();
        } else if(
            lowerCase.includes('job') ||
            lowerCase.includes('work') ||
            lowerCase.includes('career') ||
            lowerCase.includes('living')
        ) {
            return this.actionProvider.handleJob();
        } else if (
            lowerCase.includes('number') ||
            lowerCase.includes('digits') ||
            lowerCase.includes('in touch with') ||
            lowerCase.includes('contact')
        ) {
                return this.actionProvider.handleDeets();
        } else if (
            lowerCase.includes('elevator pitch') ||
            lowerCase.includes('why josh')
        ) {
            return this.actionProvider.elevatorPitch();
        } else if(
            lowerCase.includes("joshing me")
        ) {
            return this.actionProvider.handleJoshing();
        } else if(
            lowerCase.includes("where is josh") ||
            lowerCase.includes("located") ||
            lowerCase.includes("live") ||
            lowerCase.includes("he from") || lowerCase.includes("josh from")
        ) {
            return this.actionProvider.handleWhereFrom();
        } else if(
            lowerCase.includes("nevermind") ||
            lowerCase.includes("stop") ||
            lowerCase.includes("no")
        ) {
            return this.actionProvider.handleBack();
        }

        else {
            return this.actionProvider.handleFallback();
        }
    }
}

export default MessageParser;