import React, { Component } from 'react';
import me from '../resources/img/me.jpg';


class Header extends Component {
    state = {  }
    render() { 
        return (
            <header>
                <div className="container">
                    <div className="card">
                        <img className="mePic" src={me} alt="It me" />
                        <div className="headerText">
                            <h1>Howdy!</h1>
                            <p>I'm Josh</p>
                        </div>
                    </div>
                </div>
            </header>
         );
    }
}
 
export default Header;