import React, { Component } from 'react';

class Deets extends Component {
    state = {  }
    render() { 
        return (
        <div className="yesNoButtons">
            <button className="yes" onClick={this.props.actionProvider.handleYes}>Yes!</button>
            <button className="no" onClick={this.props.actionProvider.handleNo}>No.</button>
        </div>);
    }
}
 
export default Deets;