class ActionProvider {
    constructor(createChatBotMessage, setStateFunc, createClientMessage) {
      this.createChatBotMessage = createChatBotMessage;
      this.setState = setStateFunc;
      this.createClientMessage = createClientMessage;
    }

    handleColors = () => {
        const message = this.createChatBotMessage(
            "Josh's favorite colors are blue and purple!"
        );

        this.addMessageToBotState(message);
    }

    handlePets = () => {
        const message = this.createChatBotMessage(
            "Josh has a loooovely rabbit named Harper! Here, let me grab a pic...", {widget: 'harper'}
        );
    
        this.addMessageToBotState(message);
    }

    handleJob = () => {
        const messages = [
            this.createChatBotMessage("Josh works in electoral politics helping to get Democrats elected to office!"),
            this.createChatBotMessage("...basically he spends his whole day writing emails to trick old people into giving money to Democrats.", {delay: 3500}),
            this.createChatBotMessage("Pretty riveting stuff.", {delay: 7000})
        ]

        this.addMessageToBotState(messages);
    }

    handleWhereFrom = () => {
        const messages = [
            this.createChatBotMessage("Josh was born and raised in New Jersey. Apart from a brief stint in Boston and Syracuse, NY for school, here's lived here his whole life!"),
        ];

        this.addMessageToBotState(messages);
    }

    handleJoshing = () => {
        const messages = [
            this.createClientMessage("Are you Joshing me right now?"),
            this.createChatBotMessage("I am not!", {delay: 2000})
        ];
        

        this.addMessageToBotState(messages);
    }

    elevatorPitch = () => {
        const messages = [
            this.createChatBotMessage("Look, I'm a little biased in part because I am literally programmed with the sole function of wingmanning Josh...", {widget: 3500}),
            this.createChatBotMessage("But damn if Josh isn't the best (and only) person I know anything about.")
        ]

        this.addMessageToBotState(messages);
    }

    handleExplicit = () => {
        const messages = [
            this.createChatBotMessage(
                "WOAH there! Sounds like you might be asking about something INAPPROPS ;)"
            ),
            this.createChatBotMessage(
                "I'm just a digital assistant so there's not much I can help with ya there."
            )
        ];

        this.addMessageToBotState(messages);
    }

    handleDeets = () => {
        const message = this.createChatBotMessage("Are you looking to get in touch with Josh?", {widget: 'deets'});

        this.addMessageToBotState(message);
    }

    handleHobbies = () => {
        const message = [
            this.createChatBotMessage("Josh likes cooking and baking, some video games here or there, a whole lot of chess, and building lots of pointless websites!"),
            this.createChatBotMessage("Thank God for that last one, right? Otherwise I wouldn't exist!")
        ];


        this.addMessageToBotState(message);
    }

    handleBack = () => {
        const message = this.createChatBotMessage("Oh, ok then! Is there something else I can help you out with?");


        this.addMessageToBotState(message);
    }


    handleYes = () => {
        const messages = this.createChatBotMessage("Great! You've got a few options. Choose the contact method you'd like to use below...", {widget: 'contact'});

        this.addMessageToBotState(messages);
    }

    handleNo = () => {
        const messages = this.createChatBotMessage("Oh, my mistake! If you'd like to learn something about Josh, choose from these options below!", {widget: 'options'});

        this.addMessageToBotState(messages);
    }

    handleFallback = () => {
        const potentials = [
            this.createChatBotMessage("Whoops! Didn't quite catch that. I'm just a bot, so cut me a little slack! Here are a few things I can tell you about Josh, or try typing another message!", {widget: 'options'}),
            this.createChatBotMessage("Yikes, looks like I don't have an answer for that one. Here are a few things I can tell you about Josh, or you can try typing another message!", {widget: 'options'}),
            this.createChatBotMessage("Oh no! I'm drawing a blank on that. Try typing another message, or you can choose from the options below to learn something else about Josh...", {widget: 'options'})
        ]

        const rand = potentials[Math.floor((Math.random()*potentials.length))];

        this.addMessageToBotState(rand);
    }

    addMessageToBotState = (messages, newState) => {
        if (Array.isArray(messages)) {
          this.setState((state) => ({
            ...state,
            ...newState,
            messages: [...state.messages, ...messages],
            gist: "",
            infoBox: "",
          }));
        } else {
          this.setState((state) => ({
            ...state,
            ...newState,
            messages: [...state.messages, messages],
            gist: "",
            infoBox: "",
          }));
        }
      };
}
  
export default ActionProvider;